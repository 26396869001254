import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: process.env.NODE_ENV=='development'?'http://localhost:8081/api/v1/':'https://app.petsomy.com/api/v1/',
  timeout: 25000
})

Vue.prototype.$http = axiosIns

export default axiosIns
