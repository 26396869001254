export default [
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('@/views/Admin/Home/Admin'),
    meta: {
      resource: 'admin',
    },
  },
  {
    path: '/veterinarian/home',
    name: 'vet-home',
    component: () => import('@/views/Homepages/Veterinarian'),
    meta: {
      resource: 'vet',
    },
  },
  {
    path: '/groomer/home',
    name: 'groomer-home',
    component: () => import('@/views/Homepages/Groomer'),
    meta: {
      resource: 'groomer',
    },
  },
  {
    path: '/petshop/home',
    name: 'petshop-home',
    component: () => import('@/views/Homepages/Petshop'),
    meta: {
      resource: 'petshop',
    },
  },
]
