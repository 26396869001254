export default [

    {
        path: '/admin/expertises',
        name: 'admin_expertises',
        component: () => import('@/views/Admin/Expertises/list.vue'),
        meta: {
            resource: 'admin_services',
            pageTitle: 'Hizmetler',
            breadcrumb: [
                {
                    text: 'Hizmetler',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/services',
        name: 'admin_services',
        component: () => import('@/views/Admin/Services/list.vue'),
        meta: {
            resource: 'admin_services',
            pageTitle: 'Servisler',
            breadcrumb: [
                {
                    text: 'Servisler',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/membership-types',
        name: 'membership_types',
        component: () => import('@/views/Admin/MembershipTypes/list.vue'),
        meta: {
            resource: 'membership_types',
            pageTitle: 'Kullanıcı Türleri',
            breadcrumb: [
                {
                    text: 'Kullanıcı Türleri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/membership-types/:type',
        name: 'membership_packages',
        component: () => import('@/views/Admin/MembershipTypes/packages.vue'),
        meta: {
            resource: 'membership_types',
            pageTitle: 'Kullanıcı Paketleri',
            breadcrumb: [
                {
                    text: 'Kullanıcı Türleri',
                    t0: {name:'membership_types'}
                },
                {
                    text: 'Kullanıcı Paketleri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/settings',
        name: 'admin_settings',
        component: () => import('@/views/Admin/Settings/list.vue'),
        meta: {
            resource: 'settings',
            pageTitle: 'Genel Ayarlar',
            breadcrumb: [
                {
                    text: 'Genel Ayarlar',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/pet-types',
        name: 'admin_pettypes',
        component: () => import('@/views/Admin/Pet/list.types.vue'),
        meta: {
            resource: 'pettype',
            pageTitle: 'Pet Türleri',
            breadcrumb: [
                {
                    text: 'Pet Türleri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/pet-vaccination-types',
        name: 'admin_pet_vac_types',
        component: () => import('@/views/Admin/Pet/list.vacTypes.vue'),
        meta: {
            resource: 'pettype',
            pageTitle: 'Pet Aşı Türleri',
            breadcrumb: [
                {
                    text: 'Pet Aşı Türleri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/product-brand',
        name: 'admin_product_brands',
        component: () => import('@/views/Admin/Product/Brand/list.vue'),
        meta: {
            resource: 'admin_product',
            pageTitle: 'Ürün Markaları',
            breadcrumb: [
                {
                    text: 'Ürün Markaları',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/product-category',
        name: 'admin_product_category',
        component: () => import('@/views/Admin/Product/Category/list.vue'),
        meta: {
            resource: 'admin_product',
            pageTitle: 'Ürün Kategorileri',
            breadcrumb: [
                {
                    text: 'Ürün Kategorileri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/product-unit-types',
        name: 'admin_product_unit_type',
        component: () => import('@/views/Admin/Product/UnitTypes/list.vue'),
        meta: {
            resource: 'admin_product',
            pageTitle: 'Ürün Birim Cinsleri',
            breadcrumb: [
                {
                    text: 'Ürün Birim Cinsleri',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/faq',
        component: () => import('@/views/Admin/Faq/sBase.vue'),
        children: [
            {
                path: '/',
                name: 'faq_categories',
                component: () => import('@/views/Admin/Faq/Category/list.vue'),
                meta: {
                    resource: 'faq',
                    pageTitle: 'SSS',

                    breadcrumb: [
                        {
                            text: 'SSS Kategorileri',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'questions/:category',
                name: 'admin_faq_list',
                component: () => import('@/views/Admin/Faq/Question/list.vue'),
                meta: {
                    resource: 'faq',
                    pageTitle: 'SSS',
                    breadcrumb: [
                        {
                            text: 'SSS Kategoriler',
                            to: {name: 'faq_categories'}
                        },
                        {
                            text: 'SSS Sorular',
                            active: true
                        }
                    ]
                }
            },
            {
                path: 'questions',
                name: 'admin_faq_list_empty',
                component: () => import('@/views/Admin/Faq/Question/list.vue'),
                meta: {
                    resource: 'faq',
                    pageTitle: 'SSS',
                    breadcrumb: [
                        {
                            text: 'SSS Sorular',
                            active: true
                        }
                    ]
                }
            }
        ]
    },

    {
        path: '/admin/question-suggestions',
        name: 'admin_question_suggestion',
        component: () => import('@/views/Admin/Suggestion/list.vue'),
        meta: {
            resource: 'question_suggestion',
            pageTitle: 'Soru-Öneriler',
            breadcrumb: [
                {
                    text: 'Soru-Öneriler',
                    active: true
                }
            ]
        }
    },

    {
        path: '/admin/blog',
        component: () => import('@/views/Admin/Blog/sBase.vue'),
        children: [
            {
                path: '/',
                name: 'admin_blog',
                component: () => import('@/views/Admin/Blog/Category/list.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',

                    breadcrumb: [
                        {
                            text: 'Blog Kategorileri',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'contents/:category',
                name: 'admin_blog_list',
                component: () => import('@/views/Admin/Blog/Content/list.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',
                    breadcrumb: [
                        {
                            text: 'Blog Kategoriler',
                            to: {name: 'admin_blog'}
                        },
                        {
                            text: 'Blog İçerikleri',
                            active: true
                        }
                    ]
                }
            },
            {
                path: 'contents',
                name: 'admin_blog_list_empty',
                component: () => import('@/views/Admin/Blog/Content/list.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',
                    breadcrumb: [
                        {
                            text: 'Blog İçerikleri',
                            active: true
                        }
                    ]
                }
            },
            {
                path: 'content/edit/:id',
                name: 'blog_content_edit',
                component: () => import('@/views/Admin/Blog/Content/edit.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',
                    breadcrumb: [
                        {
                            text: 'Blog İçerikleri',
                            active: true
                        }
                    ]
                }
            },
            {
                path: 'content/add',
                name: 'blog_content_add_empty',
                component: () => import('@/views/Admin/Blog/Content/add.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',
                    breadcrumb: [
                        {
                            text: 'Blog Ekle',
                            active: true
                        }
                    ]
                }
            },
            {
                path: 'content/add/:category',
                name: 'blog_content_add',
                component: () => import('@/views/Admin/Blog/Content/add.vue'),
                meta: {
                    resource: 'blog',
                    pageTitle: 'Blog',
                    breadcrumb: [
                        {
                            text: 'Blog Ekle',
                            active: true
                        }
                    ]
                }
            }
        ]
    },

    {
        path: '/admin/users',
        component: () => import('@/views/Admin/Users/sBase.vue'),
        children: [
            {
                path: '/',
                name: 'users',
                component: () => import('@/views/Admin/Users/list.vue'),
                meta: {
                    resource: 'users',
                    pageTitle: 'Kullanıcılar',

                    breadcrumb: [
                        {
                            text: 'Kullanıcılar',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: ':page',
                name: 'users_paged',
                component: () => import('@/views/Admin/Users/list.vue'),
                meta: {
                    resource: 'users',
                    pageTitle: 'Kullanıcılar',

                    breadcrumb: [
                        {
                            text: 'Kullanıcılar',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                name: 'users_detail',
                component: () => import('@/views/Admin/Users/detail.vue'),
                meta: {
                    resource: 'users',
                    pageTitle: 'Kullanıcı Detay',
                    breadcrumb: [
                        {
                            text: 'Kullanıcılar',
                            to: {name: 'users'}
                        },
                        {
                            text: 'Kullanıcı Detay',
                            active: true
                        }
                    ]
                }
            }
        ]
    },

]
