export default [
  {
    path: '/products',
    component: () => import('@/views/Products/pBase.vue'),
    children: [
      {
        path:'/',
        name: 'products',
        component: () => import('@/views/Products/list.vue'),
        meta: {
          resource: 'products',
          pageTitle: 'Ürün Yönetimi',

          breadcrumbMenu: [
            {
              title:'Ekle',
              icon:'PlusIcon',
              to: {name:'add-product'}
            }
          ],
          breadcrumb: [
            {
              text: 'E Ticaret',
            },
            {
              text: 'Ürünler',
              active: true,
            },
          ],
        },
      },
      {
        path:'add',
        name:'add-product',
        component: () => import('@/views/Products/add.vue'),
        meta: {
          resource: 'products',
          pageTitle: 'Ürün Ekle',
          breadcrumb: [
            {
              text:'E Ticaret'
            },
            {
              text: 'Ürün Yönetimi',
              to: {name: 'products'},
            },
            {
              text: 'Ürün Ekle',
              active: true
            }
          ]
        }
      },
      {
        path:'edit/:product',
        name:'edit-product',
        component: () => import('@/views/Products/edit.vue'),
        meta: {
          resource: 'products',
          pageTitle: 'Ürün Düzenle',
          breadcrumb: [
            {
              text:'E Ticaret'
            },
            {
              text: 'Ürün Yönetimi',
              to: {name: 'products'},
            },
            {
              text: 'Ürün Düzenle',
              active: true
            }
          ]
        }
      }
    ]
  },


  {
    path: '/orders',
    component: () => import('@/views/Orders/sBase.vue'),
    children: [
      {
        path:'/',
        name: 'orders',
        component: () => import('@/views/Orders/list.vue'),
        meta: {
          resource: 'orders',
          pageTitle: 'Sipariş Yönetimi',
          breadcrumb: [
            {
              text: 'E Ticaret',
            },
            {
              text: 'Siparişler',
              active: true,
            },
          ],
        },
      },
      {
        path:'/orders/:page',
        name:'orders_paged',
        component: () => import('@/views/Orders/list.vue'),
        meta: {
          resource: 'orders',
          pageTitle: 'Sipariş Yönetimi',
          breadcrumb: [
            {
              text: 'E Ticaret',
            },
            {
              text: 'Siparişler',
              active: true,
            },
          ],
        },
      },

      {
        path:'orders/detail/:order',
        name:'order_detail',
        component: () => import('@/views/Orders/detail.vue'),
        meta: {
          resource: 'orders',
          pageTitle: 'Sipariş Yönetimi',
          breadcrumb: [
            {
              text: 'E Ticaret',
            },
            {
              text: 'Siparişler',
              to: {name:'orders'},
            },
            {
              text: 'Sipariş Detay',
              active: true,
            },
          ],
        },
      }
    ]
  },

]
